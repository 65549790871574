import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import RegisterContent from "page_components/register/RegisterContent"

const Register = () => {
  const breadcrumbs_data = [
    {
      name: "Rejestracja",
      href: "/rejestracja/",
    },
  ]

  return (
    <Layout
      seo={{
        title: "Rejestracja",
        description:
          "Podolog. Umów wizytę telefonicznie lub online, tel.: 500 301 420",
      }}
      popImageRm
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Rejestracja" />
      <RegisterContent />
    </Layout>
  )
}

export default Register
